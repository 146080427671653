import React, { useContext } from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink";
import { useTheme } from "styled-components";
import { SiteSettingsContext } from "../../../context/SiteSettingsContext";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Container from "../../common/Container";
import RaindrumULogo from "../../common/Logos/RaindrumULogo";
import RecentArticles from "../RecentArticles";
import S from "./styles";
import { ContentfulArticle, PathContext } from "../../../interfaces/index";
import {
  contentfulRichTextLinks,
  contentfulRichTextInlineImage,
} from "../../../utils/helpers";

interface ArticleContentProps extends ContentfulArticle {
  pathContext?: PathContext;
  recentArticles?: ContentfulArticle[];
}

const ArticleContent = ({
  title,
  image,
  content,
  pathContext: { pageMap },
  author,
  date,
  category,
}: ArticleContentProps) => {
  const { settings } = useContext(SiteSettingsContext);
  const { colors } = useTheme();

  return (
    <S.ArticleContent>
      <Container>
        <AniLink
          className="back-link"
          to={settings.insightsPageSlug ? settings.insightsPageSlug : "/"}
          cover
          bg="white"
          direction="left"
        >
          Back to Insights
        </AniLink>
        <div className="content">
          {image && (
            <div className="image">
              <img srcSet={image.fluid.srcSet} />
            </div>
          )}
          {title && <h1 className="title">{title}</h1>}
          {content && (
            <div className="copy">
              {documentToReactComponents(content, {
                renderNode: {
                  ...contentfulRichTextLinks({ pageMap }),
                  ...contentfulRichTextInlineImage,
                },
              })}
            </div>
          )}
          <div className="category-type">
            <span>
              <AniLink
                to={`${settings.insightsPageSlug}/${category.slug}`}
                cover
                bg="white"
                direction="right"
              >
                {category.title}
              </AniLink>
            </span>
            <RaindrumULogo
              fill={colors.darkGrey}
              style={{ width: "30px", height: "30px" }}
            />
          </div>
          <div className="author">
            {author ? author.name : "Raindrum"} | {date}
          </div>
        </div>
      </Container>
      <RecentArticles
        title="Recent Articles"
        carouselSettings={{
          breakPoints: [
            { width: 1, itemsToShow: 1.2 },
            { width: 750, itemsToShow: 2.5 },
            { width: 1200, itemsToShow: 3.5 },
          ],
        }}
      />
    </S.ArticleContent>
  );
};

export default ArticleContent;

import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import { SiteSettingsContext } from "../../context/SiteSettingsContext";
import { HeaderContext } from "../../context/HeaderContext";
import Layout from "../layout";
import Meta from "../../components/content/Meta";
import ArticleContent from "../../components/content/ArticleContent";

export const query = graphql`
  query ArticleQuery($contentful_id: String!) {
    articleData: contentfulArticle(contentful_id: { eq: $contentful_id }) {
      title
      slug
      date(formatString: "Do MMM, YYYY")
      category {
        title
        slug
      }
      excerpt {
        excerpt
      }
      image {
        fluid(quality: 80, maxWidth: 1000) {
          ...GatsbyContentfulFluid_withWebp
        }
        fixed {
          src
        }
      }
      metadata {
        ...Metadata
      }
      content {
        json
      }
    }
  }
`;

const Article = ({ data, pathContext }) => {
  const { setTheme, setPageTheme, setHeaderVisible } = useContext(
    HeaderContext
  );
  const { setSettings } = useContext(SiteSettingsContext);

  if (!data?.articleData) {
    return null;
  }

  const {
    title = "",
    image,
    slug,
    category,
    type,
    excerpt,
    content,
    metadata = {},
    author,
    date,
  } = data.articleData;

  useEffect(() => {
    if (pathContext.settings) {
      setSettings(pathContext.settings);
    }

    setTheme("dark");
    setPageTheme("dark");
    setHeaderVisible(true);
  }, [pathContext]);

  return (
    <Layout>
      <Meta
        meta={{
          title: `${title} | ${category.title} - Raindrum`,
          ogTitle: `${title} | ${category.title} - Raindrum`,
          description: { description: excerpt.excerpt },
          ogDescription: { ogDescription: excerpt.excerpt },
          ogImage: image,
          twitterImage: image,
          twitterDescription: { twitterDescription: excerpt.excerpt },
          ...metadata,
        }}
        defaults={pathContext.settings.defaultPageMetadata}
      />
      <ArticleContent
        title={title}
        slug={slug}
        image={image}
        category={category}
        type={type}
        content={content ? content.json : null}
        pathContext={pathContext}
        recentArticles={data?.recentArticles?.edges}
        author={author}
        date={date}
      />
    </Layout>
  );
};

export default Article;

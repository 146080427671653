import styled from "styled-components";

const ArticleContent = styled.article`
  position: relative;
  padding: 130px 0 0 0;

  .back-link {
    font-size: 18px;
    line-height: 32px;
    font-family: ${({ theme }) => theme.fonts.ttNormsBold};
    border-bottom: 2px solid ${({ theme }) => theme.colors.darkGrey};
    text-decoration: none;
    padding-bottom: 5px;
    display: inline-block;
    letter-spacing: 1px;
  }

  .content {
    position: relative;
    padding: 80px 0 130px 0;
    max-width: 666px;
    margin: 0 auto;

    .image {
      position: relative;
      width: 100%;
      max-height: 420px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .title {
      font-size: 35px;
      line-height: 40px;
      margin-top: 40px;
      color: ${({ theme }) => theme.colors.darkGrey3};
    }

    .copy {
      position: relative;
      font-size: 20px;
      line-height: 28px;
      white-space: break-spaces;
      word-break: break-word;
      border-bottom: 1px solid ${({ theme }) => theme.colors.darkGrey3};
      padding-bottom: 30px;

      h2 {
        font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
        font-size: 24px;
        line-height: 36px;
      }

      p {
        color: ${({ theme }) => theme.colors.mediumGrey2};

        b {
          font-family: ${({ theme }) => theme.fonts.ttNormsMedium};
        }
      }

      hr {
        margin: 20px 0;
      }

      img {
        max-width: 100%;
        margin: 20px auto;
      }

      blockquote {
        position: relative;
        padding: 20px 0;
        margin: 0;

        p {
          font-family: ${({ theme }) => theme.fonts.butlerMedium};
          color: ${({ theme }) => theme.colors.darkGrey3};
          font-size: 30px;
          line-height: 40px;
          margin: 0;
          padding: 0;
        }
      }
    }

    .category-type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      font-size: 16px;
      line-height: 36px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.mediumGrey2};

      a {
        text-decoration: none;
      }
    }

    .author {
      font-size: 16px;
      line-height: 36px;
      padding-bottom: 20px;
      color: ${({ theme }) => theme.colors.mediumGrey2};
    }
  }
`;

export default {
  ArticleContent,
};
